import { useEffect, useState } from "react";
import UISidebar from '../Domain/Components/UI/Sidebar/Sidebar';
import './App.css';
import { Link, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEarthAmericas, faUser } from "@fortawesome/free-solid-svg-icons";
import { faSackDollar } from "@fortawesome/free-solid-svg-icons/faSackDollar";
import UINavbar from "../Domain/Components/UI/Navbar/Navbar";
import LoginPage from "./Pages/Login/LoginPage";
import { getWithExpiry } from "../Domain/Helpers/LocalStorageHelper";
import { logoutCall } from "../Infrastructure/Services/AuthService";
import CurrencyListPage from "./Pages/Currency/CurrencyList";
import CurrencyDetailsPage from "./Pages/Currency/CurrencyDetails";
import CurrencyInsertPage from "./Pages/Currency/CurrencyInsert";
import CurrencyUpdatePage from "./Pages/Currency/CurrencyUpdate";
import CurrencyRateListPage from "./Pages/CurrencyRate/CurrencyRateList";
import CurrencyRateDetailsPage from "./Pages/CurrencyRate/CurrencyRateDetails";
import CurrencyRateInsertPage from "./Pages/CurrencyRate/CurrencyRateInsert";
import CurrencyRateUpdatePage from "./Pages/CurrencyRate/CurrencyRateUpdate";
import OperatorListPage from "./Pages/Operator/OperatorList";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons/faBriefcase";
import OperatorInsertPage from "./Pages/Operator/OperatorInsert";
import OperatorUpdatePage from "./Pages/Operator/OperatorUpdate";
import OperatorDetailsPage from "./Pages/Operator/OperatorDetails";
import GameConfigListPage from "./Pages/GameConfig/GameConfigList";
import { faDiceSix } from "@fortawesome/free-solid-svg-icons/faDiceSix";
import GameConfigInsertPage from "./Pages/GameConfig/GameConfigInsert";
import GameConfigUpdatePage from "./Pages/GameConfig/GameConfigUpdate";
import GameConfigDetailsPage from "./Pages/GameConfig/GameConfigDetails";
import GameTagListPage from "./Pages/GameTag/GameTagList";
import GameTagInsertPage from "./Pages/GameTag/GameTagInsert";
import GameTagUpdatePage from "./Pages/GameTag/GameTagUpdate";
import GameTagDetailsPage from "./Pages/GameTag/GameTagDetails";
import logo from "./Assets/Images/flexGamesLogo.png";
import ProviderListPage from "./Pages/Provider/ProviderList";
import ProviderInsertPage from "./Pages/Provider/ProviderInsert";
import ProviderUpdatePage from "./Pages/Provider/ProviderUpdate";
import ProviderDetailsPage from "./Pages/Provider/ProviderDetails";
import ProviderUserRoleAttachPage from "./Pages/Provider/ProviderUserRoleAttach";
import ProviderUserRoleUpdatePage from "./Pages/Provider/ProviderUserRoleUpdate";
import ProviderUserRoleDetailsPage from "./Pages/Provider/ProviderUserRoleDetails";
import PermissionListPage from "./Pages/Permission/PermissionList";
import { faGear } from "@fortawesome/free-solid-svg-icons/faGear";
import PermissionInsertPage from "./Pages/Permission/PermissionInsert";
import PermissionDetailsPage from "./Pages/Permission/PermissionDetails";
import CountryListPage from "./Pages/Country/CountryList";
import CountryInsertPage from "./Pages/Country/CountryInsert";
import CountryUpdatePage from "./Pages/Country/CountryUpdate";
import CountryDetailsPage from "./Pages/Country/CountryDetails";
import RoleListPage from "./Pages/Role/RoleList";
import RoleInsertPage from "./Pages/Role/RoleInsert";
import RoleUpdatePage from "./Pages/Role/RoleUpdate";
import RoleDetailsPage from "./Pages/Role/RoleDetails";
import PlayerListPage from "./Pages/Player/PlayerList";
import PlayerInsertPage from "./Pages/Player/PlayerInsert";
import PlayerUpdatePage from "./Pages/Player/PlayerUpdate";
import PlayerDetailsPage from "./Pages/Player/PlayerDetails";
import PlayerCurrencyAttachPage from "./Pages/Player/PlayerCurrencyAttach";
import PlayerCurrencyUpdatePage from "./Pages/Player/PlayerCurrencyUpdate";
import PlayerCurrencyDetailsPage from "./Pages/Player/PlayerCurrencyDetails";
import UserListPage from "./Pages/User/UserList";
import UserInsertPage from "./Pages/User/UserInsert";
import UserUpdatePage from "./Pages/User/UserUpdate";
import UserDetailsPage from "./Pages/User/UserDetails";
import UserProviderUserRoleAttachPage from "./Pages/User/UserProviderUserRoleAttach";
import UserProviderUserRoleUpdatePage from "./Pages/User/UserProviderUserRoleUpdate";
import LanguageListPage from "./Pages/Language/LanguageList";
import LanguageInsertPage from "./Pages/Language/LanguageInsert";
import LanguageUpdatePage from "./Pages/Language/LanguageUpdate";
import LanguageDetailsPage from "./Pages/Language/LanguageDetails";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OperatorGameConfigAttachPage from "./Pages/GameConfig/OperatorGameConfigAttach";
import OperatorGameConfigUpdatePage from "./Pages/GameConfig/OperatorGameConfigUpdate";
import OperatorGameConfigDetailsPage from "./Pages/GameConfig/OperatorGameConfigDetails";

function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loggedIn, setLoggedIn] = useState(getWithExpiry("token") !== null);
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  } 

  const navigate = useNavigate();
  const location = useLocation();

  const [sideBarContents, setSideBarContents] = useState([
    {
      submenuTitle: "Financial",
      submenuIcon: <FontAwesomeIcon icon={faSackDollar} />,
      contents: [
        {
          linkEl: <Link to="/currency">Currency</Link>,
          path: "/currency"
        }
        
      ]
    },
    {
      submenuTitle: "Account",
      submenuIcon: <FontAwesomeIcon icon={faUser} />,
      contents: [
        {
          linkEl: <Link to="/login">Login</Link>,
          path: "/login"
        }
      ]
    }
  ]);

  useEffect(() => {
    if (loggedIn) {
      let hideAdminSection = false;
      let roles = getWithExpiry("roles");
      if (!roles) {
        setLoggedIn(false);
        return;
      }
      if (roles.filter(x => x.includes('admin')).length === 0) {
        hideAdminSection = true;
      }
      setSideBarContents([
        {
          submenuTitle: "Financial",
          submenuIcon: <FontAwesomeIcon icon={faSackDollar} />,
          contents: [
            {
              linkEl: <Link to="/currency">Currency</Link>,
              path: "/currency"
            },
            {
              linkEl: <Link to="/currency-rate">Currency Rate</Link>,
              path: "/currency-rate"
            }
          ]
        },
        {
          submenuTitle: "Global",
          submenuIcon: <FontAwesomeIcon icon={faEarthAmericas} />,
          contents: [
            {
              linkEl: <Link to="/country">Country</Link>,
              path: "/country"
            },
            {
              linkEl: <Link to="/language">Language</Link>,
              path: "/language"
            }
          ]
        },
        {
          submenuTitle: "Partners",
          submenuIcon: <FontAwesomeIcon icon={faBriefcase} />,
          contents: [
            {
              linkEl: <Link to="/operator">Operator</Link>,
              path: "/operator"
            },
            {
              linkEl: <Link to="/provider">Provider</Link>,
              path: "/provider"
            }
          ]
        },
        {
          submenuTitle: "Gaming",
          submenuIcon: <FontAwesomeIcon icon={faDiceSix} />,
          contents: [
            {
              linkEl: <Link to="/player">Player</Link>,
              path: "/player"
            },
            {
              linkEl: <Link to="/game-config">Game Config</Link>,
              path: "/game-config"
            },
            {
              linkEl: <Link to="/game-tag">Game Tag</Link>,
              path: "/game-tag"
            }
          ]
        },
        {
          submenuTitle: "Admin",
          submenuIcon: <FontAwesomeIcon icon={faGear} />,
          hidden: hideAdminSection,
          contents: [
            {
              linkEl: <Link to="/permission">Permission</Link>,
              path: "/permission"
            },
            {
              linkEl: <Link to="/role">Role</Link>,
              path: "/role"
            },
            {
              linkEl: <Link to="/user">User</Link>,
              path: "/user"
            }
          ],
        },
        {
          submenuTitle: "Account",
          submenuIcon: <FontAwesomeIcon icon={faUser} />,
          contents: [
            {
              linkEl: <button onClick={logout}>Logout</button>,
              path: null
            }
          ]
        }
      ]);
      if(window.location.href.includes("login")) {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        let returnUrl = params.get('returnUrl');
        navigate(returnUrl ? returnUrl : "/currency");
      }
    } else {
      setSideBarContents([
        {
          submenuTitle: "Account",
          submenuIcon: <FontAwesomeIcon icon={faUser} />,
          contents: [
            {
              linkEl: <Link to="/login">Login</Link>,
              path: "/login"
            }
          ]
        }
      ]);
      localStorage.removeItem("roles");
      if (!location.pathname.includes("login"))
        navigate(`/login?returnUrl=${location.pathname}${location.search}`);
    }
  }, [loggedIn])

  const logout = async() => {
    var token = getWithExpiry("token");
    if (!token) {
      setLoggedIn(false);
    }
    var result = await logoutCall(token);
    if (result) {
      setLoggedIn(false);
    }
  }

  return (
    <div id="wrapper" className={`${sidebarOpen ? 'toggled' : ''}`}>
      <ToastContainer position="bottom-right" 
                    autoClose={10000}
                    theme="dark"
                    />
      <div className="overlay"></div>

      <UISidebar contents={sideBarContents} logoSrc={logo}/>
      <div id="page-content-wrapper">
        <UINavbar toggleSidebar={toggleSidebar}/>
        <div className="container app-container">
          <Routes>
            <Route path="/login" element={<LoginPage setLoggedIn={setLoggedIn} loggedIn={loggedIn}/>} />

            {/* Currency pages */}
            <Route path="/currency" element={<CurrencyListPage setLoggedIn={setLoggedIn}/>} />
            <Route path="/currency/details" element={<CurrencyDetailsPage setLoggedIn={setLoggedIn}/>} />
            <Route path="/currency/create" element={<CurrencyInsertPage setLoggedIn={setLoggedIn} />} />
            <Route path="/currency/update" element={<CurrencyUpdatePage setLoggedIn={setLoggedIn} />} />

            {/* Currency Rate pages */}
            <Route path="/currency-rate" element={<CurrencyRateListPage setLoggedIn={setLoggedIn}/>} />
            <Route path="/currency-rate/details" element={<CurrencyRateDetailsPage setLoggedIn={setLoggedIn} />} />
            <Route path="/currency-rate/create" element={<CurrencyRateInsertPage setLoggedIn={setLoggedIn} />} />
            <Route path="/currency-rate/update" element={<CurrencyRateUpdatePage setLoggedIn={setLoggedIn} />} />

            {/* Operator pages */}
            <Route path="/operator" element={<OperatorListPage setLoggedIn={setLoggedIn} />} />
            <Route path="/operator/create" element={<OperatorInsertPage setLoggedIn={setLoggedIn} />} />
            <Route path="/operator/update" element={<OperatorUpdatePage setLoggedIn={setLoggedIn} />} />
            <Route path="/operator/details" element={<OperatorDetailsPage setLoggedIn={setLoggedIn} />} />
            
            {/* Game Config pages */}
            <Route path="/game-config" element={<GameConfigListPage setLoggedIn={setLoggedIn}/>} />
            <Route path="/game-config/create" element={<GameConfigInsertPage setLoggedIn={setLoggedIn} />} />
            <Route path="/game-config/update" element={<GameConfigUpdatePage setLoggedIn={setLoggedIn} />} />
            <Route path="/game-config/details" element={<GameConfigDetailsPage setLoggedIn={setLoggedIn} />} />
            <Route path="/game-config/operator/attach" element={<OperatorGameConfigAttachPage setLoggedIn={setLoggedIn} />} />
            <Route path="/game-config/operator/update" element={<OperatorGameConfigUpdatePage setLoggedIn={setLoggedIn} />} />
            <Route path="/operator-game-config/details" element={<OperatorGameConfigDetailsPage setLoggedIn={setLoggedIn} />} />

            {/* Game Tag pages */}
            <Route path="/game-tag" element={<GameTagListPage setLoggedIn={setLoggedIn}/>} />
            <Route path="/game-tag/create" element={<GameTagInsertPage setLoggedIn={setLoggedIn}/>} />
            <Route path="/game-tag/update" element={<GameTagUpdatePage setLoggedIn={setLoggedIn} />} />
            <Route path="/game-tag/details" element={<GameTagDetailsPage setLoggedIn={setLoggedIn} />} />

            {/* Player pages */}
            <Route path="/player" element={<PlayerListPage setLoggedIn={setLoggedIn}/>} />
            <Route path="/player/create" element={<PlayerInsertPage setLoggedIn={setLoggedIn}/>} />
            <Route path="/player/update" element={<PlayerUpdatePage setLoggedIn={setLoggedIn}/>} />
            <Route path="/player/details" element={<PlayerDetailsPage setLoggedIn={setLoggedIn}/>} />
            
            {/* Player Currency pages */}
            <Route path="/player/currency/attach" element={<PlayerCurrencyAttachPage setLoggedIn={setLoggedIn} />} />
            <Route path="/player/currency/update" element={<PlayerCurrencyUpdatePage setLoggedIn={setLoggedIn} />} />
            <Route path="/player-currency/details" element={<PlayerCurrencyDetailsPage setLoggedIn={setLoggedIn} />} />

            {/* Provider pages */}
            <Route path="/provider" element={<ProviderListPage setLoggedIn={setLoggedIn}/>} />
            <Route path="/provider/create" element={<ProviderInsertPage setLoggedIn={setLoggedIn}/>} />
            <Route path="/provider/update" element={<ProviderUpdatePage setLoggedIn={setLoggedIn}/>} />
            <Route path="/provider/details" element={<ProviderDetailsPage setLoggedIn={setLoggedIn}/>} />

            {/* Provider user role pages */}
            <Route path="provider/user-role/attach" element={<ProviderUserRoleAttachPage setLoggedIn={setLoggedIn} />} />
            <Route path="user/provider-role/attach" element={<UserProviderUserRoleAttachPage setLoggedIn={setLoggedIn} />} />
            <Route path="provider/user-role/update" element={<ProviderUserRoleUpdatePage setLoggedIn={setLoggedIn} />} />
            <Route path="provider/user-role/details" element={<ProviderUserRoleDetailsPage setLoggedIn={setLoggedIn} />} />
            <Route path="user/provider-role/update" element={<UserProviderUserRoleUpdatePage setLoggedIn={setLoggedIn} />} />
            
            {/* Permission pages */}
            <Route path="/permission" element={<PermissionListPage setLoggedIn={setLoggedIn}/>} />
            <Route path="/permission/create" element={<PermissionInsertPage setLoggedIn={setLoggedIn}/>} />
            <Route path="/permission/details" element={<PermissionDetailsPage setLoggedIn={setLoggedIn} />} />

            {/* Country pages */}
            <Route path="/country" element={<CountryListPage setLoggedIn={setLoggedIn}/>} />
            <Route path="/country/create" element={<CountryInsertPage setLoggedIn={setLoggedIn} />} />
            <Route path="/country/update" element={<CountryUpdatePage setLoggedIn={setLoggedIn} />} />
            <Route path="/country/details" element={<CountryDetailsPage setLoggedIn={setLoggedIn} />} />

            {/* Language routes */}
            <Route path="/language" element={<LanguageListPage setLoggedIn={setLoggedIn}/>} />
            <Route path="/language/create" element={<LanguageInsertPage setLoggedIn={setLoggedIn} />} />
            <Route path="/language/update" element={<LanguageUpdatePage setLoggedIn={setLoggedIn} />} />
            <Route path="/language/details" element={<LanguageDetailsPage setLoggedIn={setLoggedIn} />} />
            
            {/* Role pages */}
            <Route path="/role" element={<RoleListPage setLoggedIn={setLoggedIn}/>} />
            <Route path="/role/create" element={<RoleInsertPage setLoggedIn={setLoggedIn}/>} />
            <Route path="/role/update" element={<RoleUpdatePage setLoggedIn={setLoggedIn}/>} />
            <Route path="/role/details" element={<RoleDetailsPage setLoggedIn={setLoggedIn}/>} />

            {/* User pages */}
            <Route path="/user" element={<UserListPage setLoggedIn={setLoggedIn}/>} />
            <Route path="/user/create" element={<UserInsertPage setLoggedIn={setLoggedIn}/>} />
            <Route path="/user/update" element={<UserUpdatePage setLoggedIn={setLoggedIn}/>} />
            <Route path="/user/details" element={<UserDetailsPage setLoggedIn={setLoggedIn}/>} />

          </Routes>
        </div>
      </div>

    </div>
  );
}

export default App;
