import React, { useEffect, useState } from "react";
import { getAllActive } from "../../../Infrastructure/Services/DataService";
import { getWithExpiry } from "../../../Domain/Helpers/LocalStorageHelper";
import { formatArrayForSelectInput } from "../../../Domain/Helpers/SelectInputHelper";
import { validateTextInput } from "../../../Domain/Helpers/InputValidationHelper";
import { inputType } from "../../../Domain/Constants/InputType";
import GenericInsertUpdatePage from "../../../Domain/Components/Functional/GenericInsertPage/GenericInsertPage";
import { toast } from "react-toastify";

function GameConfigInsertPage(props) {
    const [providers, setProviders] = useState(null);
    const [gameTags, setGameTags] = useState(null);
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        const token = getWithExpiry("token");

        if (token === null) {
            props.setLoggedIn(false);
            return;
        }

        getAllActive("provider/read", token, false, ["parent_provider"]).then((data) => {
            if (data === null) {
                toast.error("Failed to load providers");
            } else {
                setProviders(data.filter(x => x.parent_provider !== null));
            }
        }).catch((ex) => {
            toast.error(ex);
        });

        getAllActive("game-tag/read", token).then((data) => {
            if (data === null) {
                toast.error("Failed to load game tags");
            } else {
                setGameTags(data);
            }
        }).catch((ex) => {
            toast.error(ex);
        });
    }, []);

    useEffect(() => {
        if (providers && gameTags) {
            setColumns([
                {
                    inputType: inputType.TEXT,
                    labelText: "Name",
                    required: true,
                    inputId: "name",
                    validationFunction: (value) => validateTextInput(value, "Name", true)
                },        
                {
                    inputType: inputType.SELECT,
                    labelText: "Provider",
                    required: true,
                    inputId: "provider_id",
                    selectOptions: formatArrayForSelectInput(providers, "name~parent_provider.name"),
                    validationFunction: () => {return null}
                },
                {
                    inputType: inputType.SELECT,
                    labelText: "Game Tag",
                    required: false,
                    inputId: "game_tag_id",
                    selectOptions: formatArrayForSelectInput(gameTags, "name"),
                    validationFunction: () => {return null}
                }
            ])
        }
    }, [providers, gameTags])

    return (
        <GenericInsertUpdatePage inputs={columns}
            setLoggedIn={props.setLoggedIn}
            endpoint={"game-config/create"}
            pageTitle={"Create Game Config"}
            isUpdate={false}
            entityName={"Game Config"} />
    )
}

export default GameConfigInsertPage;