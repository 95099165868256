import React from "react";
import GenericListPage from "../../../Domain/Components/Functional/GenericListPage/GenericListPage";

function GameConfigListPage(props) {
    return(
        <>
            <GenericListPage setLoggedIn={props.setLoggedIn}
                            deleteModalTitile={"Delete Game Config"}
                            deleteModalQuestion={"Are you sure you want to delete this game config?"}
                            editHref={"/game-config/update"}
                            detailsHref={"/game-config/details"}
                            objectKeys={["id", "name", "provider.name", "provider.parent_provider.name"]}
                            columnTitles={[
                                "ID", "Name", "Provider", "Agregator"
                            ]}
                            insertHref={"/game-config/create"}
                            insertTitle={"Add Game Config"}
                            pageTitle={"Game Configs"}
                            deleteEndpoint={"game-config/delete"}
                            readEndpoint={"game-config/read"}
                            relations={["provider", "provider.parent_provider"]}
                            />
        </>
    )
}

export default GameConfigListPage;