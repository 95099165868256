export const formatArrayForSelectInput = (arrData, display, isNullable = false) => {
    let arrayToDisplay = [];
    arrayToDisplay = arrData.map((obj, i) => {
        const objToDisplay = {
            value: obj.id,
            display: display.includes("~") ? getObjectByKey(obj, display.split("~")[0]) + ", " + getObjectByKey(obj, display.split("~")[1]) : getObjectByKey(obj, display)
        }
        return objToDisplay;
    });
    if (isNullable) {
        arrayToDisplay.unshift({
            value: null,
            display: ''
        })
    }
    return arrayToDisplay;
}

const getObjectByKey = (obj, key) => {
    if (key.includes(".")) {
        let keys = key.split(".");

        let returnVal = obj[keys[0]];

        for (let i = 1; i < keys.length; i++) {
            returnVal = returnVal[keys[i]];
        }

        return returnVal
    }

    return obj[key];
}