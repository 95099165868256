import React from "react";
import GenericDetailsPage from "../../../Domain/Components/Functional/GenericDetailsPage/GenericDetailsPage";

function OperatorGameConfigDetailsPage(props) {
    return <GenericDetailsPage setLoggedIn={props.setLoggedIn}
                            title={"Operator Game Config Details"}
                            endpoint={"operator-game-config/read"}
                            relations={["operator", "game_config"]} />
}

export default OperatorGameConfigDetailsPage;