import { toast } from "react-toastify";
import { postApiCall } from "../Helpers/HttpHandler"

export const getById = async(endpoint, id, token, relations = null, withoutIsActiveFilter = false) => {
    let body = {
        offset: 0,
        limit: 1,
        relations: relations,
        values: withoutIsActiveFilter ? {id: id} : {id: id, is_active: "1"}
    }
    let response = await postApiCall(endpoint, body, token);

    if (response.error) {
        toast.error(response.error.message);
        return null;
    }

    if (response.data === null || response.data.data.length === 0) {
        return null;
    }

    return response.data.data[0];
}

export const deleteById = async (endpoint, id, token) => {
    let body = {
        id: id.toString()
    };

    let response = await postApiCall(endpoint, body, token);

    if (response.error) {
        toast.error(response.error.message);
        return false;
    }

    if (response.data === null) {
        return false;
    }

    return response.data.success;
}

export const insertEntity = async (endpoint, formData, token) => {
    formData["is_active"] = true;

    let response = await postApiCall(endpoint, formData, token);

    return response;
}

export const updateEntity = async (endpoint, formData, token) => {
    formData["is_active"] = true;
    formData["id"] = (new URLSearchParams(window.location.search)).get("id");

    let response = await postApiCall(endpoint, formData, token);

    return response;
}

export const getAllActive = async (endpoind, token, withoutIsActive = false, relations = []) => {
    let body = withoutIsActive ? {
        relations: relations
    } : {
        values: {is_active: "1"},
        relations: relations
    }

    let response = await postApiCall(endpoind, body, token);

    if (response.error) {
        toast.error(response.error.message);
    }

    if (response.data === null) {
        return null;
    }

    return response.data.data;
}