import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import UILabelInput from "../../UI/LabelInput/LabelInput";
import { useNavigate } from "react-router-dom";
import UIButton from "../../UI/Button/Button";
import { styleType } from "../../../Constants/StyleType";
import { getWithExpiry } from "../../../Helpers/LocalStorageHelper";
import { getById, insertEntity, updateEntity } from "../../../../Infrastructure/Services/DataService";
import { inputType } from "../../../Constants/InputType";
import { toast } from "react-toastify";

function GenericInsertUpdatePage(props) {
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [entity, setEntity] = useState(null);
    const [submitClicked, setSubmitClicked] = useState(false);

    useEffect(() => {
        const roles = getWithExpiry("roles");
        if (!roles) {
            props.setLoggedIn(false);
            return;
        }

        if (roles.filter(x => x === "admin" || x === "super-admin").length === 0 || 
        (!roles.includes("super-admin") && props.onlyReadForAdmin)) {
            navigate(-1)
        }
        
        if (props.isUpdate) {
            const token = getWithExpiry("token");

            if (token == null) {
                props.setLoggedIn(false);
                return;
            }

            const id = (new URLSearchParams(window.location.search)).get("id");

            getById(props.getEndpoint, id, token, null, props.withoutIsActiveFilter).then((data) => {
                if (data === null) {
                    toast.error("Cannot find entity")
                } else {
                    setEntity(data);
                    
                }
            }).catch((err) => {
                toast.error(err);
            })
        }
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (submitClicked) return;

        const token = getWithExpiry("token");

        if (token == null) {
            props.setLoggedIn(false);
            return;
        }

        setSubmitClicked(true);
        const formData = new FormData(e.target);
        const formDataEntries = Object.fromEntries(formData);

        let tmpValErrors = {};

        props.inputs.forEach((input) => {
            if (input.disabled) {
                formDataEntries[input.inputId] = document.getElementById(input.inputId).value;
            }

            if (input.inputType === inputType.DATE) {
                formDataEntries[input.inputId] = formDataEntries[input.inputId] + " 00:00:00";
            }

            let valErr = input.validationFunction(formDataEntries[input.inputId]);

            if (valErr) {
                tmpValErrors[input.inputId] = valErr;
            }
        });

        if (Object.keys(tmpValErrors).length > 0) {
            setErrors(tmpValErrors);
            setSubmitClicked(false);
            return;
        }

        for (const key in formDataEntries) {
            if (formDataEntries[key] === "") {
                delete formDataEntries[key];
            }
        }

        try {
            const response = props.isUpdate ? await updateEntity(props.endpoint, formDataEntries, token) : await insertEntity(props.endpoint, formDataEntries, token);

            if (response.data) {
                toast.success(`${props.entityName ? props.entityName : "Entity"} ${props.isUpdate ? "updated" : "inserted"} successfully`,
                    {
                        autoClose: 3000
                    }
                );
                navigate(-1);
            } else {
                tmpValErrors = {};
                for (const key in response.errors) {
                    tmpValErrors[key] = response.errors[key][0];
                }
                setErrors(tmpValErrors);
                setSubmitClicked(false);
            }
        } catch (ex) {
            toast.error(ex)
        }
    }

    return (
        <>{
            (!props.isUpdate || (props.isUpdate && entity)) && <>
                <p className="h3 m-2">{props.pageTitle}</p>
                <div className="row">
                    <form method="post" onSubmit={handleSubmit} className="col-md-8 offset-md-2">
                        {props.inputs.map((input, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <UILabelInput inputType={input.inputType}
                                        labelText={input.labelText}
                                        disabled={input.disabled}
                                        required={input.required}
                                        inputId={input.inputId}
                                        defaultValue={props.isUpdate ? entity[input.inputId]?.toString() : null}
                                        numberStep={input.numberStep}
                                        invalidFeedback={errors.hasOwnProperty(input.inputId) ? errors[input.inputId] : null}
                                        selectOptions={props.isUpdate && input.selectOptions && input.selectOptions.filter(x => x.value === entity[input.inputId]).length == 0 ? [...input.selectOptions, {value: entity[input.inputId], display: `Inactive ${input.labelText}`}] : input.selectOptions}
                                        numberMin={input.numberMin}
                                        numberMax={input.numberMax}
                                    />
                                </React.Fragment>)
                        })}

                        <div className="d-flex justify-content-end mt-2">
                            <UIButton buttonText={"Cancel"} buttonType={"button"} styleType={styleType.SECONDARY} onClick={() => navigate(-1)} />
                            <span className="ms-2"></span>
                            <UIButton buttonText={props.isUpdate ? "Update" : "Insert"} buttonType={"submit"} styleType={styleType.FADEBLACK} />
                        </div>
                    </form>
                </div>
            </>
        }
        </>
    )
}

GenericInsertUpdatePage.propTypes = {
    pageTitle: PropTypes.string,
    inputs: PropTypes.arrayOf(PropTypes.object),
    setLoggedIn: PropTypes.func,
    endpoint: PropTypes.string,
    isUpdate: PropTypes.bool,
    withoutIsActiveFilter: PropTypes.bool,
    onlyReadForAdmin: PropTypes.bool,
    entityName: PropTypes.string
}

export default GenericInsertUpdatePage;