import React, { useRef, useState } from "react";
import GenericDetailsPage from "../../../Domain/Components/Functional/GenericDetailsPage/GenericDetailsPage";
import { getWithExpiry } from "../../../Domain/Helpers/LocalStorageHelper";
import { deleteById } from "../../../Infrastructure/Services/DataService";
import UIModal from "../../../Domain/Components/UI/Modal/Modal";

function GameConfigDetailsPage(props) {
    const [deleteOperatorGameConfigModalOpen, setDeleteOperatorGameConfigModalOpen] = useState(false);
    const [deleteOperatorGameConfigId, setOperatorGameConfigId] = useState(null);
    const detailsPageComponentRef = useRef({});

    const onDeleteOperatorGameConfigClicked = (id) => {
        setOperatorGameConfigId(id);
        setDeleteOperatorGameConfigModalOpen(true);
    }

    const onCancelDelete = () => {
        setOperatorGameConfigId(null);
        setDeleteOperatorGameConfigModalOpen(false);
    }

    const deleteOperatorGameConfig = async () => {
        var token = getWithExpiry("token");

        if (token === null) {
            props.setLoggedIn(false);
            return;
        }

        var result = await deleteById("operator-game-config/delete", deleteOperatorGameConfigId, token);

        if (result) {
            setDeleteOperatorGameConfigModalOpen(false);
            setOperatorGameConfigId(null);
            detailsPageComponentRef.current.loadData();
        }
    }

    return <><GenericDetailsPage setLoggedIn={props.setLoggedIn}
                            title={"Game Config Details"}
                            editHref={"/game-config/update"}
                            endpoint={"game-config/read"}
                            relations={["provider", "game_tag", "operator_game_configs", "operator_game_configs.operator"]}
                            manyToManyRelations={[{
                                name: "operator_game_configs",
                                title: "Operators",
                                attachHref: {
                                    href: "/game-config/operator/attach",
                                    title: "Attach Operator"
                                },
                                columnTitles: ["Operator", "Internal Game ID", "Provider Game ID"],
                                objectKeys: ["operator.company_name", "internal_game_id", "provider_game_id"],
                                editHref: "/game-config/operator/update",
                                detailsHref: "/operator-game-config/details",
                                deleteClicked: onDeleteOperatorGameConfigClicked
                            }]}
                            ref={detailsPageComponentRef} />

<UIModal showModal={deleteOperatorGameConfigModalOpen}
            onClose={onCancelDelete}
            onConfirm={deleteOperatorGameConfig}
            title={"Delete Operator Game Config"}
            body={"Are you sure you wnat to delete this operator game config?"}
            confirmButtonText={"Delete"}
        />
            </>
}

export default GameConfigDetailsPage;